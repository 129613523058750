<template>
    <div>
        <div class="topic-title" id="listen-select">
            <div class="topic-title__wrapper">
                <span class="topic-title__text">{{ item.title }}</span>
                <span class="topic-title__text">{{ item.deputy }}</span>
            </div>
        </div>
        <template v-if="item.children == undefined || item.children == null || item.children.length <= 0">
            <div class="topic-content" v-if="item.materials">
                <div class="topic-content__img">
                    <ul class="img-list">
                        <template v-for="(c_item, c_index) in item.materials.images">
                            <li :key="c_index">
                                <img :src="item.src" alt="">
                                <span>{{ c_index | getSerial }}</span>
                            </li>
                        </template>
                    </ul>
                    <template v-if="item.materials.content">
                        <div v-html="item.materials.content"></div>
                    </template>
                </div>
            </div>

            <div class="topic-content" v-for="(g_item, g_index) in item.timu" :key="g_index">
                <span class="topic-content__title"></span>
                <span class="topic-content__title">
                </span>
                <div class="topic-content__img">
                    <div class="topic-content__body">
                        <div class="body-title" v-if="g_item.title !== '' && g_item.title !== null">
                            <span>{{ g_item.num }}.</span>
                            <span v-html="g_item.title"></span>
                        </div>
                        <template v-if="g_item.type == 1 || g_item.type == 2">
                            <ul class="body-list1">
                                <li v-for="(h_item, h_index)  in g_item.value" :key="h_index">
                                    <span class="list-num same-num" v-if="g_item.type === '' || g_item.title == null">
                                        {{ g_item.num }}.
                                    </span>
                                    <el-radio v-model="h_item.answer" v-for="(i_item, i_index) in h_item.item"
                                        :key="i_index" :label="i_item.num"
                                        :disabled="h_item.answer!=null?true:false"
                                        @change="radioChange(g_item, h_item, i_item.num)"
                                        :class="[{'right-checked':h_item.is_right === 1 && h_item.answer == i_item.num},{'err-checked':h_item.is_right === 0 && h_item.answer==i_item.num}]"
                                        >
                                        <span class="list-title same-title">{{ i_item.num |
                                                getSerial
                                        }}</span>
                                        <span class="list-answer same-answer">{{ i_item.value
                                        }}</span>
                                    </el-radio>
                                </li>
                            </ul>
                        </template>
                        <template v-if="g_item.type == 3">
                            <el-radio-group v-model="g_item.answer">
                                <el-radio :label="judge_item.id" @change="radioChangeStage(g_item, judge_item.id)"
                                    v-for="(judge_item, judge_index) in judge_item" :key="judge_index"
                                    :disabled="g_item.answer!=null?true:false"
                                    :class="[{ 'right-checked': g_item.is_right === 1 && g_item.answer == judge_item.id }, { 'err-checked': g_item.is_right === 0 && g_item.answer == judge_item.id }]">

                                    <span class="list-title same-title">
                                        {{ judge_index | getSerial }}
                                    </span>
                                    <span class="list-answer same-answer">
                                        {{ judge_item.name }}
                                    </span>
                                </el-radio>
                            </el-radio-group>
                        </template>
                        <template v-if="g_item.type == 4">
                            <div class="topic-content__body">
                                <input type="text" v-model="g_item.answer"
                                    v-on:input="pin_input(g_item, g_item.answer)">
                            </div>
                        </template>
                        <template v-if="g_item.type == 5">
                            <div class="topic-content__body">
                                <el-input type="textarea" autosize v-model="g_item.answer"
                                    v-on:input="pin_input(g_item, g_item.answer)"></el-input>
                            </div>
                        </template>
                        <!--<el-radio-group>
                                                    <el-radio>
                                                        <span class="list-title same-title">A. </span>
                                                        <span class="list-answer same-answer">
                                                            apple
                                                        </span>
                                                    </el-radio>
                                                </el-radio-group>-->
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import { TYPE_ARR, PRIMARY_TYPE_ARR, AllDEBTER, AllTERM, SERIAL_NUM, JUDGE_ITEM } from "@/common/config/data.js"

export default {
    props: {
        item: { type: Object },
    },
    data() {
        return {
            chooseAudios: {
                src: require("../../assets/audio/check.wav"),
                errSrc: require("../../assets/audio/error.mp3"),
            },
            judge_item: JUDGE_ITEM,
        }
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    created() {

    },
    methods: {
        chooseAudio(type = 1) {
            let audio = new Audio();
            audio.controls = false;
            let src = this.chooseAudios.src;
            if (type === 0) {
                src = this.chooseAudios.errSrc;
            }
            audio.src = src;
            audio.play();
        },
        radioChange(timu_item, timu_value_item, num) {
            if (timu_value_item.right_answer == num) {
                timu_value_item.is_right = 1;
                this.chooseAudio(1);
                //console.log('right');
            } else {
                this.chooseAudio(0);
                timu_value_item.is_right = 0;
                //console.log('error');
            }

            let is_right = 1;//是否正确
            let is_anwwer = 1;//是否已做
            for (let i = 0; i < timu_item.value.length; i++) {
                //timu_item.value[i].is_right = 0;
                if (timu_item.value[i].is_right == 0) {
                    is_right = 0;
                }
                if (timu_item.value[i].answer === '' || timu_item.value[i].answer === null) {
                    is_anwwer = 0;
                    //console.log('is_answer', is_anwwer);
                }
            }
            timu_item.is_right = is_right;
            if (timu_item.is_right == 1) {
                timu_item.answer_score = timu_item.score;
            }else{
                timu_item.answer_score = 0;
            }
            timu_item.is_answer = is_anwwer;
            //单选和选择填空
            //console.log('timu_item', timu_item);
            //console.log('timu_value_item', timu_value_item);
            //console.log('num', num);
        },
        radioChangeStage(timu_item, index) {
            //判断
            if (timu_item.right_answer == index) {
                timu_item.is_right = 1;
                this.chooseAudio(1);
                 timu_item.answer_score = timu_item.score;
                //console.log('is_right', index);
            } else {
                timu_item.is_right = 0;
                 timu_item.answer_score = 0;
                this.chooseAudio(0);
                //console.log('error', index);
            }
            timu_item.is_answer = 1;// 标记为已做
            //console.log('timu_item', timu_item);
        },
        pin_input(g_item, answer) {
            //console.log('g_item', g_item);
            //console.log("answer", answer);
            if (answer == '' || answer == null) {
                g_item.is_answer = 0;
            } else {
                g_item.is_answer = 1;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.right-checked {
    span {
        color: #FFF;
    }
    ::v-deep .el-radio__label {
        margin-left: 5px;
        padding: 6px 10px 3px 10px;
        text-align: center;
        color: #fff;
        background-color: #409EFF;
        border-radius: 13px;
    }
    ::v-deep .el-radio__inner {
        border-color: #409EFF !important;
        background-color: #409EFF !important;
    }
    .same-title {
        color: #fff;
    }
    ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
        background-color: #fff;
    }
}

.err-checked {
    span {
        color: #FFF;
    }

    .el-radio__input.is-checked .el-input__inner {
        border-color: #FF3F3F !important;
        background-color: #FF3F3F !important;
    }

    ::v-deep .el-radio__inner {
        border-color: #FF3F3F !important;
        background-color: #FF3F3F !important;
    }

    ::v-deep .el-radio__label {
        margin-left: 5px;
        padding: 6px 10px 3px 10px;
        text-align: center;
        color: #fff;
        background-color: red;
        border-radius: 13px;
    }
    ::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
        background-color: #fff;
    }
    .same-title {
        color: #fff;
    }
}
</style>
