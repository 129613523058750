<template>
    <el-row class="paper-wrong">
        <template v-if="spacialStatus == 0">
            <el-col class="paper-wrong__topbar">
                <div class="topbar-content">
                    <div class="topbar-content__left">心铭远单词速记 > 我的错题</div>
                    <div class="topbar-content__right">
                        <el-button type="text" size="small" round @click="save">保 存</el-button>
                        <el-button type="text" size="small" round @click="gotoXuexi">返 回</el-button>
                    </div>
                </div>
            </el-col>
            <el-col class="paper-wrong__body">
                <el-col class="paper-wrong__content">
                    <div class="content-header">
                        <el-form :model="form" label-width="100px">
                            <!--<el-form-item label="试卷类型：">
                            <el-radio v-model="form.shijuan_type_id" :label="1" @change="changeState(1)">小学试卷</el-radio>
                            <el-radio v-model="form.shijuan_type_id" :label="2" @change="changeState(2)">初中试卷</el-radio>
                        </el-form-item>-->
                            <el-form-item label="试卷板块：" v-if="state">
                                <el-select v-model="form.timu_type_arr" multiple collapse-tags placeholder="请选择"
                                    size="mini">
                                    <el-option v-for="item in type_arr" :key="item.id" :label='item.name'
                                        :value="item.id">
                                        <!--<span
                                        style="display: inline-block; margin-right: 10px; width: 30px; text-align: center;">{{
                                                item.id
                                        }}</span>-->
                                        <span>{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button type="primary" size="small" @click="getErrorTimuFn">获取错题</el-button>
                                <el-button size="small" @click="reSetForm">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div class="content-body">
                        <div class="content-body__header">
                            <span class="content-body__header--left">错题题目</span>
                            <div class="content-body__header--right">
                                <span>只测错题（随机）</span>
                                <ul>
                                    <li>
                                        <el-radio v-model="form.pageSize" :label="5">5道</el-radio>
                                    </li>
                                    <li>
                                        <el-radio v-model="form.pageSize" :label="10">10道</el-radio>
                                    </li>
                                    <li>
                                        <el-radio v-model="form.pageSize" :label="20">20道</el-radio>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="content-body__content">
                            <div class="paper-wrapper__content listen" v-if="lists.length>0">
                                <div class="topic" v-for="(item, index) in lists">
                                    <timu :item="item" />
                                    <template
                                        v-if="item.children == undefined || item.children == null || item.children.length <= 0">
                                    </template>
                                    <template v-else>
                                        <template v-for="(children_item, children_index) in item.children">
                                            <timu :item="children_item"></timu>
                                        </template>

                                    </template>
                                </div>
                            </div>
                            <div v-else>
                                <div>目前没有错题哦~~</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-col>
        </template>
        <template v-if="spacialStatus == 1 && correctIdArr.length > 0">
            <div>
                <div class="paper-wrong__btn--text">还有 {{ correctIdArr.length }} 大道题未批改，去批改</div>
                <div>
                    <el-button type="primary" @click="toCorrectFn">去批改</el-button>
                </div>
            </div>
        </template>
        <!--  批改试卷  -->

        <template v-if="spacialStatus == 2 && correctIdArr.length > 0">
            <div class="paper-wrong__bar">
                <div class="paper-wrong__bar--content">
                    <span @click="correctSave">保存批改</span>
                    <span @click="gotoXuexi">返回</span>
                </div>
            </div>
            <template v-for="(item, index) in lists">
                <template v-if="item.is_pigai == 0">
                    <div class="topic" :key="index">
                        <div class="correct-wrapper">
                            <div style="margin-top: 50px;">
                                <correct-timu :item="item"></correct-timu>

                                <template
                                    v-if="item.children == undefined || item.children == null || item.children.length <= 0">
                                </template>
                                <template v-else>
                                    <template v-for="(children_item, children_index) in item.children">
                                        <correct-timu :item="children_item"></correct-timu>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </template>

        <div v-if="spacialStatus == 1 || spacialStatus == 3">
            <div class="paper-practice__title">
                <div class="paper-practice__title--content">
                    <span @click="gotoXuexi">返回</span>
                </div>
            </div>
            <div class="paper-practice__total">
                <div>
                    <i class="fa fa-thumbs-o-up"></i>
                    <span>正确</span>
                    <span>{{ rightCount }}</span>
                </div>

                <div>
                    <i class="fa fa-thumbs-o-up"></i>
                    <span>错误</span>
                    <span>{{ errorCount }}</span>
                </div>
            </div>
            <div class="paper-practice__empty"></div>
        </div>
        <template v-if="spacialStatus == 1 && correctIdArr.length > 0">
            <div>
                <div>还有 {{ correctIdArr.length }} 大道题未批改，去批改</div>
                <div>
                    <el-button type="primary" @click="toCorrectFn">去批改</el-button>
                </div>
            </div>
        </template>

        <template v-if="spacialStatus == 3">
            <div class="paper-wrong__continue">
                <el-button type="primary" @click="toCorrectNextFn">继续下一轮</el-button>
            </div>
        </template>
    </el-row>
</template>

<script>
import { mapState } from "vuex";
import { TYPE_ARR, PRIMARY_TYPE_ARR, AllDEBTER, AllTERM, SERIAL_NUM, JUDGE_ITEM } from "@/common/config/data.js"
import timu from "@/components/paper_wrong/timu"
import correctTimu from '../../components/paper_wrong/correct_timu'

let that;

export default {
    name: "paper_wrong",
    components: {
        timu, correctTimu
    },
    data() {
        return {
            type_arr: PRIMARY_TYPE_ARR,//小学题型
            primary_type_arr: TYPE_ARR,//初中题型
            state: 1,

            form: {
                shijuan_type_id: 1,
                type_arr: TYPE_ARR,//初中题型
                primary_type_arr: PRIMARY_TYPE_ARR,//小学题型
                timu_type_arr: [],
                pageSize: 10,
                page: 1,
            },

            lists: [],
            total: 0,

            cateAnswer: [],
            timuAnswer: [],
            timuAnswerError: [],
            timuCount: 0,
            finishCount: 0,
            spacialStatus: 0,//0答题中，1提交（未批改）2，批改中，3批改结束
            spacialStart: 0,//0未开始，1开始
            correctIdArr: [],
            correctIndex: 0,//批改的索引
        }
    },
    created() {
        this.getErrorTimuFn();
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        rightCount() {
            return this.timuAnswer.length;
        },
        errorCount() {
            return this.timuAnswerError.length;
        },
    },
    methods: {
        initData() {

            this.cateAnswer = [];
            this.timuAnswer = [];
            this.timuAnswerError = [];
            this.timuCount = 0;
            this.finishCount = 0;
            this.spacialStatus = 0;//0答题中，1提交（未批改）2，批改中，3批改结束
            this.spacialStart = 1;//0未开始，1开始
            this.correctIdArr = [];
            this.correctIndex = 0;//批改的索引
        },
        handleCheckAllChange() {
            this.isIndeterminate = false;
        },
        changeState(type) {
            //this.state = !this.state;
            //console.log(this.state)
            //type_arr: PRIMARY_TYPE_ARR,//小学题型
            //primary_type_arr: TYPE_ARR,//初中题型
            this.form.timu_type_arr = [];
            if (type == 1) {
                this.type_arr = PRIMARY_TYPE_ARR;
            } else {
                this.type_arr = TYPE_ARR;
            }
        },
        getErrorTimuFn() {
            //console.log(this.debter_id, this.term_id, this.typeId)
            let data = {
                token: this.token,
                timu_type: this.form.timu_type_arr,
                pageSize: this.form.pageSize,
            }
            this.axios.post('/api/exam/error_timu', data).then((res) => {
                if (res.data.status_code == 200) {
                    this.lists = res.data.data.list;
                    this.total = res.data.data.total;
                    //console.log(this.specialList)
                }
            })
        },
        gotoXuexi() {
            this.$router.push({ name: "papers" });
        },
        saveDo() {
            //console.log("this.timuAnswer", this.timuAnswer);
            //console.log("this.cateAnswer", this.cateAnswer);
            //console.log(this.debter_id, this.term_id, this.typeId)
            let data = {
                token: this.token,
                mold_answer: this.cateAnswer,
                timu_answer: this.timuAnswer,
                timu_type: this.typeId,
            }
            this.axios.post('/api/exam/del_error_timu', data).then((res) => {
                if (res.data.status_code == 200) {
                    if (this.correctIdArr.length > 0) {
                        this.spacialStatus = 1;
                    } else {
                        this.spacialStatus = 3;
                    }

                    console.log(this.specialList)
                }
            })
        },
        save() {
            this.spacialStatus = 1;
            //console.log("lists", this.lists);
            this.autoCorrect(this.lists);
            this.getAnswer(this.lists);
            this.getNoCorrect();//设置批改的题目

            //this.cateAnswer,
            //this.timuAnswer
            console.log("this.cateAnswer", this.cateAnswer);
            console.log("this.timuAnswer", this.timuAnswer);
            this.saveDo();


        },
        correctSave() {
            this.spacialStatus = 3;//批改结束
            this.timuAnswer = [];
            this.timuAnswerError = [];
            this.cateAnswer = [];
            this.getAnswer(this.lists);
            this.saveDo();

        },
        toCorrectNextFn() {
            this.initData();
            //继续下一轮学习
            this.getErrorTimuFn();
            this.spacialStart = 1;
        },
        autoCorrect(specialList) {
            //自动批改
            specialList.forEach((item, index) => {
                item.is_pigai = 0;
                item.timu.forEach((item_timu) => {
                    if (item_timu.type == 1 || item_timu.type == 2) {
                        item.is_pigai = 1;//是否批改
                        item_timu.value.forEach((item_value) => {
                            if (item_value.right_answer == item_value.answer && item_value.right_answer != null) {
                                item_value.is_right = 1;
                            } else {
                                item_value.is_right = 0;
                            }
                        });
                    } else if (item_timu.type == 3) {//判断
                        item.is_pigai = 1;//是否批改
                        if (item_timu.right_answer == item_timu.answer && item_timu.right_answer != null) {
                            item_timu.is_right = 1;
                        } else {
                            item_timu.is_right = 0;
                        }
                    } else if (item_timu.type == 4) {
                        //this.correctIdArr.push(index);

                    } else if (item_timu.type == 5) {
                        //this.correctIdArr.push(index);
                    }
                });
                //console.log("item",item);
                //console.log("item.children",item.children);
                if (item.children != undefined && item.children.length > 0) {
                    this.autoCorrect(item.children);
                }
            })
        },
        getAnswer(specialList) {
            specialList.forEach((item, index) => {
                if (item.children != undefined && item.children.length > 0) {
                    this.getAnswer(item.children);//回调
                } else {
                    let all_rigth = 1;//判断是否全对
                    item.timu.forEach((item_timu) => {//题目
                        if (this.spacialStatus == 3) {
                            //手动批改操作完成
                            let timuAnswer = {};
                            timuAnswer.id = item_timu.id;
                            if (item_timu.is_right == 1) {
                                timuAnswer.is_right = 1;
                                this.timuAnswer.push(timuAnswer);
                            } else {
                                all_rigth = 0;
                                timuAnswer.is_right = 0;
                                this.timuAnswerError.push(timuAnswer);
                            }
                        } else {
                            //手动批改前
                            if (item_timu.type == 1 || item_timu.type == 2 || item_timu.type == 3) {
                                let timuAnswer = {};
                                timuAnswer.id = item_timu.id;
                                if (item_timu.is_right == 1) {
                                    timuAnswer.is_right = 1;
                                    this.timuAnswer.push(timuAnswer);
                                } else {
                                    all_rigth = 0;
                                    timuAnswer.is_right = 0;
                                    this.timuAnswerError.push(timuAnswer);
                                }
                            } else {
                                all_rigth = 0;
                            }
                        }
                    });
                    if (all_rigth == 1) {
                        //获取对或错的题
                        let cateAnswer = {};
                        cateAnswer.uuid = item.uuid;
                        cateAnswer.is_right = 1;
                        cateAnswer.title = item.title;
                        this.cateAnswer.push(cateAnswer);
                    }
                }
            });

            //获取大分类下是否全对
            specialList.forEach((item, index) => {
                if (item.children != undefined && item.children.length > 0) {
                    //this.getAnswer(item.children);//回调
                    let all_rigth = 1;//判断是否全对
                    let is_pigai = 1;//判断大类是否需要批改
                    item.children.forEach((chil_item) => {
                        chil_item.timu.forEach((timu_item) => {
                            if (timu_item.is_right == 0) {
                                all_rigth = 0;
                            }
                        })
                        if (chil_item.is_pigai == 0) {
                            is_pigai = 0;
                        }
                    });
                    item.is_pigai = is_pigai;

                    if (all_rigth == 1) {
                        //获取对或错的题
                        let cateAnswer = {};
                        cateAnswer.uuid = item.uuid;
                        cateAnswer.is_right = 1;
                        cateAnswer.title = item.title;
                        this.cateAnswer.push(cateAnswer);
                    }
                }
            });
        },
        toCorrectFn() {
            //去批改
            this.spacialStatus = 2;
            //console.log("correctIdArr",this.correctIdArr);
        },
        getNoCorrect() {
            //获取未批改
            this.lists.forEach((item, index) => {
                if (item.is_pigai == 0) {
                    this.correctIdArr.push(index);
                }
            });
        },
        reSetForm() {
            this.form.timu_type_arr = [];
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/paper_wrong.scss";
@import "~@/assets/css/test_papers.scss";

.correct-wrapper {
    margin: 0 auto;
    width: 1170px;
}
.paper-wrong__btn--text {
    margin-top: 100px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Microsoft JhengHei UI Light",serif;
    text-align: center;
    letter-spacing: .5px;
    & + div {
        margin-top: 20px;
        text-align: center;
        .el-button {
            width: 200px;
        }
    }
}
.paper-wrong__continue {
    text-align: center;

    .el-button {
        width: 200px;
    }
}
.paper-practice__title {
    height: 50px;
    line-height: 50px;
    text-align: right;
    background-color: #4f95f4;

    &--content {
        margin: 0 auto;
        width: 1170px;
        height: 50px;
    }
    span {
        display: inline-block;
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 15px;
        cursor: pointer;
    }
}
.paper-practice__total {
    margin-top: 60px;
}
</style>
